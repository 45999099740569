<template>
  <!-- Overlay component to show loading state -->
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
  >
    <!-- Search input section -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search module"
          type="text"
        />
      </b-form-group>
    </div>
    <!-- Module table component -->
    <modules-table
      :columns="columns"
      :get-link="getLink"
      :rows="rows"
      :search-term="searchTerm"
    />
  </b-overlay>
</template>

<script>
import gql from 'graphql-tag'
import ModulesTable from '@/components/modules/ModulesTable.vue'
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    ModulesTable,
  },
  data() {
    return {
      mutationLoading: false, // Loading state for mutations
      rows: [], // Data rows for the table
      searchTerm: '', // Search term for filtering modules
      columns: [
        {
          label: 'Module',
          field(row) {
            return row.title // Field for module title
          },
          tdClass: 'text-capitalize', // CSS class for table cell
        },
        {
          label: 'Description',
          field(row) {
            return row.description // Field for module description
          },
          filterOptions: {
            enabled: true, // Enable filter for this column
            customFilter: true, // Use custom filter component
            placeholder: 'Search Description', // Placeholder for filter input
          },
        },
        {
          label: 'Action',
          field: 'action', // Field for action buttons
          sortable: false, // Disable sorting for this column
        },
      ],
    }
  },
  methods: {
    /**
     * Function to get the link for a module.
     * @param {Number} moduleId - The module ID.
     * @param {String} type - The module type.
     * @returns {Object} - The link object.
     */
    getLink(moduleId, type) {
      return {
        name: 'mentor-events-select',
        params: { id: this.$route.params.id, pid: this.$route.params.pid, oid: moduleId },
        hash: `#${type}`,
      }
    },
  },

  apollo: {
    rows: {
      query() {
        return gql`
          query GetModules ($programId: Int!, $operationType: String!) {
            programs_operationstable(
              order_by: { id: desc }
              where: { program_id: { _eq: $programId }, operations_type: { _eq: $operationType} }
            ) {
              id
              title
              description
            }
          }
        `
      },
      variables() {
        return {
          programId: this.$route.params.id,
          operationType: 'SubPhase',
        }
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
