<template>
  <!-- table -->
  <vue-good-table
    :columns="columns"
    :pagination-options="{ enabled: true, perPage: pageLength }"
    :rows="rows"
    :search-options="{ enabled: true, externalQuery: searchTerm }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field == 'actions'">
        <b-button
          :to="getLink(props.row.sid, 'Exercises')"
          class="ml-50"
          size="sm"
          variant="outline-primary"
        >
          <span>Exercises</span>
        </b-button>
        <b-button
          :to="getLink(props.row.sid, 'Milestones')"
          class="ml-50"
          size="sm"
          variant="outline-primary"
        >
          <span>Milestones</span>
        </b-button>
      </span>
      <span v-if="props.column.field == 'category'">
        <b-badge
          :variant="props.formattedRow[props.column.field] == 'Assigned'
            ? 'primary'
            : 'secondary'"
        >
          {{ props.formattedRow[props.column.field] }}
        </b-badge>
      </span>
      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">Showing 1 to</span>
          <b-form-select
            v-model="pageLength"
            :options="['10', '20', '30']"
            class="mx-1"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap"> of {{ props.total }} entries </span>
        </div>
        <div>
          <b-pagination
            :per-page="pageLength"
            :total-rows="props.total"
            :value="1"
            align="right"
            class="mt-1 mb-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
            aria-label="Startup list navigation"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
                aria-label="Previous page"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
                aria-label="Next page"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BBadge, BButton, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BBadge,
  },
  props: {
    rows: {
      type: Array,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    searchTerm: {
      type: String,
      required: true,
    },
    getLink: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      pageLength: 10,
    }
  },
}
</script>
