<template>
  <!-- VueGoodTable component with various slots and templates for customization -->
  <vue-good-table
    :columns="columns"
    :pagination-options="{ enabled: true, perPage: pageLength }"
    :rows="exercises"
    :search-options="{ enabled: true, externalQuery: searchTerm }"
  >
    <!-- Custom column filter template for 'Operation' column -->
    <template #column-filter="{ column, updateFilters }">
      <span v-if="column.label === 'Operation'">
        <treeselect
          :append-to-body="true"
          :normalizer="filterNormalizer"
          :options="operations"
          class="form-control p-0 border-0"
          placeholder="Select Phase, Subphase or Activities"
          @input="(val) => updateFilters(column, val)"
        />
      </span>
    </template>

    <!-- Custom table row template -->
    <template #table-row="props">
      <!-- Action buttons for 'action' column -->
      <span v-if="props.column.field === 'action'">
        <b-button
          class="mr-50"
          size="sm"
          variant="outline-primary"
          @click="reviewOpen(props.row.id , 'open')"
        >
          Open
        </b-button>
        <b-button
          v-if="props.row.review_status === 'disabled'"
          size="sm"
          variant="outline-success"
          @click="reviewOpen(props.row.id , 'report')"
        >
          Report
        </b-button>
      </span>

      <!-- Display title and badge for 'title' column -->
      <span v-else-if="props.column.field === 'title'">
        {{ props.formattedRow[props.column.field] }}<br>
        <b-badge
          v-b-tooltip="'Review Type'"
          variant="primary"
          v-text="props.row.type"
        />
      </span>

      <!-- Display operation details for 'Operation' column -->
      <span v-else-if="props.column.label === 'Operation'">
        {{ props.row.programs_operationstable.title }} <br>
        <b-badge
          v-b-tooltip="'Operation Type'"
          variant="primary"
          v-text="props.row.programs_operationstable.operations_type"
        />
      </span>

      <!-- Default display for other columns -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- Custom pagination template at the bottom -->
    <template #pagination-bottom="props">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">Showing 1 to</span>
          <b-form-select
            v-model="pageLength"
            :options="[10, 20, 30]"
            class="mx-1"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap"> of {{ props.total }} entries </span>
        </div>
        <div>
          <b-pagination
            :per-page="pageLength"
            :total-rows="props.total"
            :value="1"
            class="mt-1 mb-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BBadge, BButton, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BBadge,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    Treeselect,
  },
  props: {
    /**
     * Array of review objects to be displayed in the table.
     * @type {Array}
     */
    exercises: {
      type: Array,
      default: () => [], // Default empty array if no data
    },
    /**
     * Array of column definitions for the table.
     * @type {Array}
     */
    columns: {
      type: Array,
      default: () => [], // Default empty array if no columns provided
    },
    /**
     * Array of operation options for the filter.
     * @type {Array}
     */
    operations: {
      type: Array,
      default: () => [], // Default empty array if no data
    },
    /**
     * Boolean indicating if the table is in loading state.
     * @type {Boolean}
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Function to generate a link for review actions.
     * @type {Function}
     */
    getLink: {
      type: Function,
      default: () => null,
    },
    /**
     * Search term for filtering the table.
     * @type {String}
     */
    searchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      /**
       * Number of rows to display per page.
       * @type {String}
       */
      pageLength: 10,
    }
  },
  methods: {
    /**
     * Opens a review based on the given id and type.
     * @param {Number} id - The ID of the review.
     * @param {String} type - The type of action to perform.
     */
    reviewOpen(id, type) {
      const route = this.getLink(id, type)
      if (route) {
        this.$router.push(route)
      }
    },
    /**
     * Normalizes the node structure for the Treeselect component.
     * @param {Object} node - The node to normalize.
     * @returns {Object} The normalized node.
     */
    filterNormalizer(node) {
      if (!node) return {}
      return {
        id: node.title || node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
