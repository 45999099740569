<template>
  <vue-good-table
    :columns="columns"
    :pagination-options="{ enabled: true, perPage: pageLength }"
    :rows="rows"
    :search-options="{ enabled: true, externalQuery: searchTerm }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span
        v-if="props.column.field === 'action'"
        class="d-flex"
      >
        <b-button
          v-if="showExercises"
          v-b-tooltip="'Manage Exercises'"
          :to="getExerciseLink(props.row.id)"
          class="mr-50"
          size="sm"
          variant="outline-primary"
        >
          Exercises
        </b-button>
        <b-button
          v-if="props.row.meeting"
          v-b-tooltip="'Join Workshop'"
          :href="props.row.meeting"
          class="mr-50"
          size="sm"
          target="_window"
          variant="outline-primary"
        >
          Join
        </b-button>
        <b-button
          v-if="props.row.recording"
          v-b-tooltip="'View Recording'"
          :href="props.row.recording"
          class="mr-50"
          size="sm"
          target="_window"
          variant="outline-primary"
        >
          Recording
        </b-button>
      </span>

      <span v-else-if="props.column.field === 'date'">
        <span v-if="!props.formattedRow[props.column.field]">
          {{
            (rows[props.row.originalIndex].begindate_timestamp ? formatDate(rows[props.row.originalIndex].begindate_timestamp) : '') +
              (rows[props.row.originalIndex].begindate_timestamp || rows[props.row.originalIndex].enddate_timestamp ? ' - ' : '') +
              (rows[props.row.originalIndex].enddate_timestamp ? formatDate(rows[props.row.originalIndex].enddate_timestamp) : '')
          }}
        </span>
      </span>

      <span v-else-if="props.column.label === 'Mentors'">
        <b-badge
          v-for="mentor in props.formattedRow[props.column.field]"
          :key="mentor"
          class="mr-1"
          variant="light-primary"
        >
          {{ mentor }}
        </b-badge>
      </span>

      <span v-else-if="props.column.label === 'Ratings'">
        <b-link
          v-if="props.formattedRow[props.column.field] != 'Write Review'"
          v-b-tooltip="'Update Your Rating'"
          class="text-decoration-underline cursor-pointer text-primary"
          @click="onReviewClick(props.row)"
        >
          {{ parseFloat(props.formattedRow[props.column.field]).toFixed(2) }}
        </b-link>
        <b-button
          v-else
          size="sm"
          variant="primary"
          @click="onReviewClick(props.row)"
        >
          Write Review
        </b-button>
      </span>

      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">Showing 1 to</span>
          <b-form-select
            v-model="pageLength"
            :options="['10', '20', '30']"
            class="mx-1"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap"> of {{ props.total }} entries </span>
        </div>
        <div>
          <b-pagination
            :per-page="pageLength"
            :total-rows="props.total"
            :value="1"
            class="mt-1 mb-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BBadge, BButton, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { formatDate } from '@/@core/utils/utils'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BBadge,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    getExerciseLink: {
      type: Function,
      required: false,
      default: () => '',
    },
    onReviewClick: {
      type: Function,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    showExercises: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pageLength: 10, // Default value for page length
    }
  },
  methods: {
    formatDate,
  },
}
</script>
