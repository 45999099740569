<template>
  <!-- table -->
  <vue-good-table
    :columns="columns"
    :pagination-options="{ enabled: true, perPage: pageLength }"
    :rows="rows"
    :search-options="{ enabled: true, externalQuery: searchTerm }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Action -->
      <span
        v-if="props.column.field === 'action'"
        class="d-flex"
      >
        <b-button
          v-b-tooltip="'Manage Workshop'"
          :to="getLink(props.row.id,'Workshops')"
          class="mr-50"
          size="sm"
          variant="outline-primary"
        >
          Workshop
        </b-button>
        <b-button
          v-b-tooltip="'Manage Masterclass'"
          :to="getLink(props.row.id,'MasterClass')"
          class="mr-50"
          size="sm"
          variant="outline-primary"
        >
          Masterclass
        </b-button>
        <b-button
          v-if="getLink(props.row.id,'Sessions')"
          v-b-tooltip="'Manage Sessions'"
          :to="getLink(props.row.id,'Sessions')"
          class="mr-50"
          size="sm"
          variant="outline-primary"
        >
          Sessions
        </b-button>
      </span>
      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">Showing 1 to</span>
          <b-form-select
            v-model="pageLength"
            :options="['10', '20', '30']"
            class="mx-1"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap"> of {{ props.total }} entries </span>
        </div>
        <div>
          <b-pagination
            :per-page="pageLength"
            :total-rows="props.total"
            :value="1"
            class="mt-1 mb-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BButton,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    getLink: {
      type: Function,
      default: null,
    },
    searchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageLength: '10',
    }
  },
}
</script>
